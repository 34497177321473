import React from 'react'
import './PartnerLogos.scss'

export default function ({ site }) {
  const logos = site.partner_logos || []
  if (logos.length < 1) return null
  return (
    <section id='partners'>
      <div className='cap-width'>
        <ul>
          {
            logos.filter(x => x.logo).map(x => <li style={{ backgroundImage: `url(${x.logo.url})` }} key={x.logo.url} />)
          }
        </ul>
      </div>
    </section>
  )
}
