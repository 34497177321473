import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { API_URL } from '../api';
import PageFooter from './PageFooter'

export default class WpContent extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    showTitle: PropTypes.bool
  }
  state = {

  }

  wpContentError() {
    this.setState({ content: 'Not found', title: 'Error' })
  }

  componentDidMount() {
    if (this.props.id) {
      fetch(API_URL + '/wp-json/wp/v2/pages/' + this.props.id)
        .then(res => res.json())
        .then(res => {
          if (!res || !res.content || !res.title) {
            this.wpContentError()
          }
          else {
            this.setState({
              content: res.content.rendered,
              title: res.title.rendered
            })
          }
        })
        .catch(err => {
          console.error(err)
          this.wpContentError()
        })
    }
  }

  render() {
    const { id, showTitle } = this.props
    const { content, title } = this.state
    return (
      <>
        <section id='intro'>
          <div className='text-intro cap-width'>
            <header>
              <h1><span>{title}</span></h1>
            </header>
          </div>
        </section>
        <article className='news texture-text'>
          <div className='cap-width'>
            <div className="content" dangerouslySetInnerHTML={{ __html: content }}></div> 
          </div>
        </article>
        <PageFooter />
      </>
    )
  }
}
