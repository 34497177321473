import React, { useState, useEffect } from 'react'
import 'whatwg-fetch'
import cn from 'classnames'
import { fetchBidenCabinet } from './api/'
import { getHeadshotSizes } from './util'
import './App.scss'
import Nav from './components/Nav'
import {
  Switch,
  Route,
  useLocation
} from 'react-router-dom'
import MainPage from './components/MainPage'
import About from './components/About'
import News from './components/News'
import POI from './components/POI'
import ScrollToTop from './components/ScrollToTop'

function App () {
  const [site, setSite] = useState({ loaded: false })
  const [open, setOpen] = useState(false)
  const hs = getHeadshotSizes(site)
  const location = useLocation()
  const pieces = location.pathname.split('/')
  const path = location.pathname.replace(/\//g, '')
  useEffect(() => {
    fetchBidenCabinet().then(info => {
      if (info) {
        setSite(Object.assign(info, { loaded: true }))
      }
      // else try again? 500/404?
    })
  }, [])
  return (
    <div className={cn('App', { 
      ['page page-' + path]: path,
      'no-hs': !hs,
      'poi-detail': pieces[1] && pieces[1] === 'persons-of-interest' && pieces[2],
      open
    })}>
      <div id='header' className='cap-width'>
        <Nav site={site} onChange={setOpen} />
      </div>
      <ScrollToTop>
        <Switch>
          <Route path='/about'>
            <About />
          </Route>
          <Route path='/news/:postId'>
            <News />
          </Route>
          <Route path='/news'>
            <News />
          </Route>
          <Route path='/persons-of-interest/:name'>
            <MainPage site={site} />
          </Route>
          <Route path='/persons-of-interest'>
            <POI site={site} />
          </Route>
          <Route path='/'>
            <MainPage site={site} />
          </Route>
        </Switch>
      </ScrollToTop>
    </div>
  )
}

export default App
